/* popover */
.popover {
  position:absolute;
  top:0;
  left:0;
  z-index:1010;
  display:none;
  max-width:415px;
  padding:1px;
  text-align:left;
  white-space:normal;
  background-clip:padding-box;
  border-radius:6px;
  background-color: #F8FBEF;
  border: none;
  width: 420px;
  -webkit-box-shadow:0 5px 10px rgba(0, 0, 0, .2);
  box-shadow:0 5px 10px rgba(0, 0, 0, .2);

  .popover-content {
    padding: 20px 15px 20px 20px;
  }
  a {
    position: absolute;
    top: 10px;
    right: 4px;
    font-size: 16px;
    font-weight: bold;
  }

  .arrow,.arrow:after{
    position:absolute;
    display:block;
    width:0;
    height:0;
    border-color:transparent;
    border-style:solid;
  }
  .arrow { border-width:21px;}
  .arrow:after { content:"";border-width:20px;}

  &.right {
    margin-left:50px;
    .arrow {
      top:42%;
      left:-21px;
      margin-top:-11px;
      border-right-color:#999;
      border-right-color:rgba(0, 0, 0, .25);
      border-left-width:0;
      &:after {
        bottom:-20px;
        left:1px;
        content:" ";
        border-right-color:#F8FBEF;
        border-left-width:0;
      }
    }
  }
  &.top {
    margin-top:-10px;
    .arrow {
      bottom:-11px;
      left:50%;
      margin-left:-11px;
      border-top-color:#999;
      border-top-color:rgba(0, 0, 0, .25);
      border-bottom-width:0;
      &:after {
        bottom:1px;
        margin-left:-10px;
        content:" ";
        border-top-color:#fff;
        border-bottom-width:0;
      }
    }
  }
  &.bottom {
    margin-top:10px;
    .arrow {
      top:-11px;
      left:50%;
      margin-left:-11px;
      border-top-width:0;
      border-bottom-color:#999;
      border-bottom-color:rgba(0, 0, 0, .25);
       &:after {
      top:1px;
      margin-left:-20px;
      content:" ";
      border-top-width:0;
      border-bottom-color:#fff;
      }
    }
  }
  &.left {
    margin-left:-10px;
    .arrow {
      top:50%;
      right:-11px;
      margin-top:-11px;
      border-right-width:0;
      border-left-color:#999;
      border-left-color:rgba(0, 0, 0, .25);
      &:after {
        right:1px;
        bottom:-10px;
        content:" ";
        border-right-width:0;
        border-left-color:#fff;
      }
    }
  }

}
.has-error, .has-success {
    .popover {
      width:420px;
      padding: 15px 15px 15px 40px;
      background: url('/bca/DataPal15_at/Images/icons/icon-error.png') no-repeat 10px 15px #FFE4E4;
      margin-left: 510px;
      margin-top: 16px;
      .screen-sm-max({
        position: relative;
        margin-left: 0;
      });
      .screen-xs({
        width:100%;
      });

    &:after{
      content: "";
      position: absolute;
      bottom: -15px;
      left: 50px;
      border-width: 15px 15px 0;
      border-style: solid;
      border-color: #FFCECE rgba(0, 0, 0, 0);
      width: 0;
      left: -20px;
      bottom: 15px;
      border-width: 10px 20px 10px 0;
      border-color: rgba(0, 0, 0, 0) #FFE4E4;
      .screen-sm-max({
        display: none;
      });
      .screen-xs({
        display: none;
      });
    }
  }
}

.bottom.has-error{
  .popover{
    &.fade.right.in {position:relative;margin-left:0;}
    &:after{
    width: 0;
    left: 16px;
    bottom: 50px;
    border-width: 0px 20px 17px 20px;
    border-color: #FFE4E4 rgba(0, 0, 0, 0);
    }
  }
}

.form-group-memorableday {
  .popover {
    margin-top:-10px;
  }
}

.screen-sm-max({
  .form-group {
    span.popover {
      display: block;
    }
  }
});
