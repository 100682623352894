
.ai-info-icon{
  color:@datapal-primary-color;
  .ai-font-size(25);
  float: right;
  padding-left: 15px;
}
.form-content{
  box-shadow:  0 -5px 5px -5px #AEADAD;
  padding-top: 20px;
  margin-top:20px;
  .form-content__heading{
    border-bottom: 1px solid #ECECEC;
    padding: 0 0 20px 0;
    i{
      color: @datapal-primary-color;
      .ai-font-size(40);
       padding-right:15px;
    }
  }
 }
.form-group-memorableday{
  .form-control{
    margin-right:6px;
  }
}
#rotator{
  img {
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
    -ms-transition: all 1s;
    }

  & img:hover{
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    }
  }
.form-group {
  position:relative;
}

.pwd-reveal{
  .pwd-reveal-image{
    top: 2em;
    position: absolute;
    border: 1px solid #cccccc;
    padding: 7px 8px;
  }
  .pwd-reveal-info{
   display: none;
  }
}

.dob-secondary{
  .screen-xs ({
      width:20%;
      display:inline-block;
  });
}
.number-mandatory{
  display: none;
}