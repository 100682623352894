/* ===========================
   Site Search : State 
   =========================== */
   
   
   
/** Full State **/
.site-search--full {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  width: 100%;
  
  
  & .site-search__label {
    
  }
  
  & .site-search__query {
    flex: 1;
  }
  & .site-search__search-button {
    
  }
  
  & .site-search__history {
    display:block;
    flex: 1 100%;
  }
}


/** Compact State **/
.site-search--compact {
  & .site-search__label {
    display:none;
  }
  
  & .site-search__query {
    width: 273px;
  }
  
  & .site-search__search-button {
    position:absolute;
    right: 5px;
    top:5px;
  }
}

/** Minimal State **/
.site-search--minimal {
  
  width:51px;
  
  &.is-open {
    width:320px;
  }
  transition: all 0.5s ease;
  & .site-search__label {
    display:none;
  }
  
  & .site-search__query {
    width: 100%;
  }
  
  & .site-search__search-button {
    position:absolute;
    right: 5px;
    top:5px;
  }
}