.ai-opacity(@opacity: 0.5) {
  -moz-opacity: @opacity;
  -khtml-opacity: @opacity;
  -webkit-opacity: @opacity;
  opacity: @opacity;
  @opperc: @opacity * 100;
  -ms-filter: ~"progid:DXImageTransform.Microsoft.Alpha(opacity=@{opperc})";
  filter: ~"alpha(opacity=@{opperc})";
}

.ai-gradient(@color: #F5F5F5, @start: #EEE, @stop: #FFF) {
  background: @color;
  background: -webkit-gradient(linear,
                               left bottom,
                               left top,
                               color-stop(0, @start),
                               color-stop(1, @stop));
  background: -ms-linear-gradient(bottom,
                                  @start,
                                  @stop);
  background: -moz-linear-gradient(center bottom,
                                   @start 0%,
                                   @stop 100%);
  background: -o-linear-gradient(@stop,
                                 @start);
  filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",@stop,@start));
}

.ai-transition(@type: all, @duration:0.2s, @ease:ease-out) {
  -webkit-transition: @type @duration @ease;
  -moz-transition: @type @duration @ease;
  -o-transition: @type @duration @ease;
  transition: @type @duration @ease;
}

.ai-font-size(@sizeValue) {
  @remValue: (@sizeValue / 10);
  @pxValue: (@sizeValue);
  font-size: ~"@{pxValue}px";
  font-size: ~"@{remValue}rem";
}

