.site-header{
  .ai-gradient (@datapal-navlight-color, @datapal-nav-color,@datapal-navlight-color);
  border-bottom: 1px solid #DDD;
  .secondary-nav a,
    .secondary-nav,
    .sign-in label {
      color:@datapal-sec-nav;
      font-weight: normal;
      .ai-font-size(13);
    }
    .user-icon-color{
      color: @datapal-primary-color;
    }
    .sign-in input{
       width: 122px !important;
    }
    .forgot-pw{
      color:@datapal-primary-color-link;
      .ai-font-size(14);
      margin:10px 0;
      text-decoration: underline;
    }

  .site-header__logo {
    .center-block();
    .size(100%, 30px);
    .img-retina("/bca/DataPal15_at/Images/IDPROTECT_Logo.png", "/bca/DataPal15_at/Images/IDPROTECT_Logo.png", 100%, 99px);
    background-repeat: no-repeat;
    background-size: contain !important;
    margin: 50px 0 0px 0px;
    text-indent: -9999px;
    .screen-xs({
      margin: 44px 0 0 0;
    });
  }
  .site-header__subtitle {
    color:@datapal-primary-color;
   .ai-font-size(8);
    margin:0 0 20px 0;
  }
  .validateError {
    display: none;
  }

.cobrand-wrap{
  .screen-xs ({
     float:left !important;
   });
  .cobrand-title{
    display: inline-block;
    color:@datapal-primary-color;
    .ai-font-size(13);
    font-weight:bold;
    text-align:right;
    margin-top:64px;
  }
  .cobrand-img{
    margin-left:5px;
  }
}



}