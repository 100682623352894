.how-it-works-list,.personal-data-list,.risk-assesment-list, .site-map-list{
    list-style-image:url('/bca/DataPal15_at/Images/icons/icon-chevron-green.png');
    li{
        margin-bottom:28px;
    }
}
.how-it-works-list{
    li{
        color: @datapal-dark-color-link;
        a{
          text-decoration:underline !important;
        }

    }
}

