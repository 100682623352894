.viewport{
  padding: 0;
  .viewportMobile {
    display:none;
  }
  .screen-sm-max({
    .viewportPC {display:none;}
    .viewportMobile {
      display:inline-block;
      font-size: 15px;
    }
    .mobile-btn{font-size: 15px;}
  })
}
.greyedouttabs{
  background: url('/bca/DataPal15_at/Images/dm-no-data_tab.png') no-repeat top center;
  background-size: 100%;
  max-width: 327px;
  min-height: 239px;
  p {
    padding: 19% 5%;
    .ai-font-size(13);
     .screen-sm-max({
      padding: 19% 2%;
    })
  }
  button{margin-bottom:15%}
}
/* light box styles*/
iframe {border:none;}
.home-modal-dialog {
  width:80% !important;
  .infotip{
    cursor: pointer;
  }
}
.loggedOutModal{
  .modal-content{
    height: auto !important
  }
}
.modal-content{
  border-radius: 6px;
  border: 2px solid #B0B0B0;
}
.modal-download-content{
  padding:25px;
}

.modal.loggedOutModal .modal-dialog, .modal.loggedOutModal .modal-content{
  max-height:240px;
  width:70%;
  margin:30px auto;
   .screen-xs({
      width:90%;
    })
}
.border-top{border-top:1px solid #D6D6D6;padding:12px 0;}
/* update profile styles*/
.update-submit{
  h3{
    border-bottom: 1px solid #ECECEC;
    padding: 0 0 20px 0;
  }
  label{
    padding-top:20px;
  }
}
.affix{width:100%;max-width:410px;}

.modal.reguserModal .modal-content{
  max-height:240px;
  width:70%;
  margin:30px auto;
   .screen-xs({
      width:90%;
    })
}

/* RISK TABLE STYLES*/
.meters-tabs{
  .screen-xs({
      margin-top: 25px;
    });

  border-bottom:none;
  li{
    min-width: 33%;
    max-width: 33%;
    &.high{
      a{color: #EF4423  !important}
    }
    &.med{
      a{color: #EECA5C !important}
    }
    &.low{
      a{color: #C4DD73 !important}
    }
    &:active{
      a{
        border: 1px solid @datapal-primary-color;
        border-bottom:1px solid @datapal-light-color !important;
      }
    }
  }
  a{
  background: none;
  border: 1px solid @datapal-primary-color !important;
  border-bottom: none !important;
  font-size: 20px;
  font-weight:600;
  border-radius:4px 4px 0 0 !important;
  &:hover{
    background-color:#fff !important;
    border-color: @datapal-primary-color;
    color: inherit;
    border-bottom: none;
  }
}
}
.tab-content {
  border:1px solid @datapal-primary-color;
  padding:21px 17px;
  &.tab-pane {
    display:none;
  }
  &.active {
    display:block;
  }
}

/* End of Risk table styles*/

