.site-map-list {
    li{
        a{
          text-decoration:underline !important;
          color:@datapal-sitemap-link !important;
        }

    }
}

