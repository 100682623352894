/* ===========================
   Default : Typography
   =========================== */

h1 {
  .ai-font-size(39);
  font-weight: 400;
  margin-bottom: 20px;
  color: @datapal-primary-color;
    .screen-xs ({
       .ai-font-size(30);
    });
}

h2 {
  .ai-font-size(30);
  margin-top: 0;
  margin-bottom:20px;
  color: @datapal-primary-color;
}

h3 {
  .ai-font-size(23);
  margin-top: 20px;
  margin-bottom: 0;
  color: @datapal-primary-color;
}

h4 {
  .ai-font-size(18);
  margin-top: 20px;
  margin-bottom: 20px;
  color: @datapal-primary-color;
}

h5 {

}

h6 {

}

p {
  .ai-font-size(14);
  color: @datapal-dark-color-link;
  line-height: 21px;
}
.question{
  color:@datapal-primary-color;
}
.ai-lead {
  .ai-font-size(16);
  font-weight: 600;
  margin-top: 18px;
  margin-bottom: 18px;
  color: @datapal-primary-color-link;
}

.ai-highlight-text {
  font-weight: bold;
  color: @datapal-primary-color-link;
}

.para-sub-header{
  .ai-font-size(18);
  color: @datapal-primary-color;
}

.shoppingbasket{
    .price-info-lg {
      .ai-font-size(16);
    }
    .price-info-sm {
      .ai-font-size(12);
    }
    .price {
      .ai-font-size(14);
      font-weight:bold;
    }
}