@font-face {
  font-family: 'Open Sans';
  src: url('@{icon-font-path}opensans/OpenSans.eot');
  src: url('@{icon-font-path}opensans/OpenSans.eot?#iefix') format('embedded-opentype'),
    url('@{icon-font-path}opensans/OpenSans.woff') format('woff'),
    url('@{icon-font-path}opensans/OpenSans.ttf')  format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('@{icon-font-path}opensans/OpenSans-Bold.eot');
  src: url('@{icon-font-path}opensans/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
    url('@{icon-font-path}opensans/OpenSans-Bold.woff') format('woff'),
    url('@{icon-font-path}opensans/OpenSans-Bold.ttf')  format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('@{icon-font-path}opensans/OpenSans-Light.eot');
  src: url('@{icon-font-path}opensans/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
    url('@{icon-font-path}opensans/OpenSans-Light.woff') format('woff'),
    url('@{icon-font-path}opensans/OpenSans-Light.ttf')  format('truetype');
  font-weight: 300;
  font-style: normal;
}