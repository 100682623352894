.panel{ border:0 !important;}

.panel-heading {
  border:#DDD 1px solid !important;
  background-color:@datapal-light-color !important;
  margin-top:10px;
  border-radius: 3px;
  color:@datapal-primary-color !important;
  a{
  background:url('/bca/DataPal15_at/Images/icons/icon-chevron-up.png') no-repeat right;
  outline:none;
  margin-bottom:10px;
  padding:10px;
  color:@datapal-primary-color !important;
  display:block;
  &.collapsed{
    background:url('/bca/DataPal15_at/Images/icons/icon-chevron-down.png') no-repeat right !important;
    color: @datapal-primary-color !important;
    }
  }
  &a:hover{text-decoration:none;}
}
.panel-collapse {
  border:1px solid #DDD;
  border-top:none;
}


