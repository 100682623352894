 .site-footer{
  border-top: 1px solid #DDD;
  padding-top: 20px;
  margin-top :20px;
    .site-footer-links{
      text-align:center;
    }
  .site-footer-links__item{
    color:@datapal-dark-color-link;
    text-decoration-color: @datapal-primary-color;
    margin-right: 10px;
    border-right: 1px solid;
    padding-right: 10px;
    &:last-child{
      border:1px solid transparent;
    }
    &:hover{
      color:@datapal-primary-color;
    }
    .screen-xs({
      float: left;
      border-right: 1px solid transparent;
    });
  }
  .site-footer__legal{
    margin-top:20px;
    .ai-font-size(11);
    text-align:center;
  }
  .site-footer__logotext{
    font-size: 11px;
    margin: 34px 20px 0 0;
    padding-left: 15px;
    display: inline-block;
    text-align:center !important;
    color:@datapal-dark-color-link;
  }
  .site-footer__logoaffinion{
    float:left;
    .screen-xs({
      float:none !important;
    });
  }
  .site-footer__logoadvocard{
    float:right;
    .screen-xs({
      float:none !important;
      margin-top:20px;
    });
  }
  .site-footer__logowrap{
    margin:0 auto !important;
    text-align:center !important;
  }

  .footer-logo-generic{
    .screen-xs({
      float: none !important;
    });
  }

}







