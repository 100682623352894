/* ==========================================================================
   Healer Classes
   ========================================================================== */

.block {display:block;}
.table {display:table;}
.table-cell {display:table-cell;}
.vmiddle {vertical-align: middle;}
.clr {clear:both;}
.full-width {width: 100%;}
.relative {position:relative;}
.absolute {position:absolute;}
.boxsizing {box-sizing:border-box;}
.notext {text-indent: -9999px;}
.bold {font-weight: bold;}
.underline {text-decoration:underline;}
.strikethrough {text-decoration: line-through;}
.noborder {border: 0 none !important;}
.noradius {border-radius: 0 !important;}
.nomargin {margin: 0 !important;}
.nopad {padding: 0 !important;}
.margin5 {margin: 5px !important;}
.pad5 {padding: 5px !important;}
.margin10 {margin: 10px !important;}
.pad10 {padding: 10px !important;}
.margin15 {margin: 15px !important;}
.pad15 {padding: 15px !important;}
.margin20 {margin: 20px !important;}
.pad20 {padding: 20px !important;}
.margin0bottom{margin-bottom:0px !important;}
.margin5top {margin-top: 5px !important;}
.margin5right {margin-right: 5px !important;}
.margin5bottom {margin-bottom: 5px !important;}
.margin5left {margin-left: 5px !important;}
.margin10top {margin-top: 10px !important;}
.margin10right {margin-right: 10px !important;}
.margin10bottom {margin-bottom: 10px !important;}
.margin10left {margin-left: 10px !important;}
.margin15top {margin-top: 15px !important;}
.margin15right {margin-right: 15px !important;}
.margin15bottom {margin-bottom: 15px !important;}
.margin15left {margin-left: 15px !important;}
.margin20top {margin-top: 20px !important;}
.margin20right {margin-right: 20px !important;}
.margin20bottom {margin-bottom: 20px !important;}
.margin20left {margin-left: 20px !important;}
.margin50top{margin-top:50px !important;}
.pad0left{padding-left: 0px !important;}
.pad5top {padding-top: 5px !important;}
.pad5right {padding-right: 5px !important;}
.pad5bottom {padding-bottom: 5px !important;}
.pad5left {padding-left: 5px !important;}
.pad10top {padding-top: 10px !important;}
.pad10right {padding-right: 10px !important;}
.pad10bottom {padding-bottom: 10px !important;}
.pad10left {padding-left: 10px !important;}
.pad15top {padding-top: 15px !important;}
.pad15right {padding-right: 15px !important;}
.pad15bottom {padding-bottom: 15px !important;}
.pad15left {padding-left: 15px !important;}
.pad20top {padding-top: 20px !important;}
.pad20right {padding-right: 20px !important;}
.pad20bottom {padding-bottom: 20px !important;}
.pad20left {padding-left: 20px !important;}
.pad25top {padding-top: 25px !important;}
.pad25left{padding-left:25px !important;}
.xsmall-txt {font-size: 10px; font-size: 1rem;}
.small-txt {font-size: 12px; font-size: 1.2rem;}
.medium-txt {font-size: 14px; font-size: 1.4rem;}
.large-txt {font-size: 16px; font-size: 1.6rem;}
.xlarge-txt {font-size: 18px; font-size: 1.8rem;}
.xxlarge-txt {font-size: 20px; font-size: 2rem;}
.lineheight200 {line-height: 200%;}
.white {color: #FFF;}
.grey {color: #CCC;}
.dark {color: #333;}
.darkgreen {color:#46833D;}
.green{color:#008a00;}
.white-bg {background-color: #FFF;}
.white-bg-promo {background-color: #FFF !important;}
.grey-bg {background-color: #F1F1F1;}
.darkgrey{color:#707173 !important;}
.nobg {background-image: none;}
.image-w-fit {background-size: 100% auto;}
.image-h-fit {background-size: auto 100%;}
.vtop { vertical-align:top !important }
.nopadding { padding: 0 !important; margin: 0 !important;}
.margL0 { margin-left:0 !important }
.dropdown-divider{border-bottom: 0.5px solid @datapal-primary-color;}
.accessibility-text{width:1px;height:1px;position:absolute;text-indent:-9999px;}