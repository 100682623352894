.alert {
  padding:15px;
  margin-bottom:20px;
  border:1px solid transparent;
  border-radius:4px;
  h4 {
    margin-top:0;
    color:inherit;
  }
  .alert-link {
    font-weight:bold;
  }
}

.alert-dismissable {
  padding-right:35px;
  .close {
    position:relative;
    top:-2px;
    right:-21px;
    color:inherit;
  }
}



.alert-success {
  color:@datapal-primary-color;
  background-color:#dff0d8;
  border-color:#d6e9c6;
  hr { border-top-color:#c9e2b3;}
  .alert-link { color:#2b542c;}
}

.alert-info {
  color:#31708f;
  background-color:#d9edf7;
  border-color:#bce8f1;
  hr { border-top-color:#a6e1ec;}
  .alert-link {
    color:#245269;
  }
}


.alert-warning {
  color:#8a6d3b;
  background-color:#fcf8e3;
  border-color:#faebcc;
  hr { border-top-color:#f7e1b5;}
  .alert-link {
    color:#66512c;
  }
}


.alert-danger {
  color:#a94442;
  background-color:#f2dede;
  border-color:#ebccd1;
  hr { border-top-color:#e4b9c0;}
  .alert-link { color:#843534;}
}

