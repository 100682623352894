
.site-navigation {
  .site-navigation__item {
    & a{
    background-color:@datapal-primary-color;
    font-weight:600;
    border: 1px solid @datapal-primary-color;
    margin-right: 2px;
    border-bottom: none;
    .ai-transition();
    &:hover,
    &:focus {
      border: 1px solid #c4c4c4;
      border-bottom: none;
    }
   }
  }
}