.tooltip-text-area{
  display:none;
  padding: 15px;
  border-radius: 4px;
  float: left;
  position: relative;
  width: 780px;
  z-index: 1000;
  .close{
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 15px;
  }
  &.popover{
    max-width: 780px;
    position: absolute;
    top: 36%;
    left:15px;
    width: 800px;
    background-color: @datapal-light-color;
    border: 1px solid @datapal-primary-color;
    .screen-xs({
      max-width: 300px;
    });
  }
}

.tooltip-text-area.popover.bottom {
  .arrow {
    top: -21px;
    left: 28px;
    margin-left: -11px;
    border-top-width: 0;
    border-bottom-color: @datapal-primary-color;
    :after {
      top: 1px;
      margin-left: -20px;
    }
  }
}
