.button-animation{
   padding-right:45px;
   text-align:left;
   font-weight:600;
   border-radius: 4px;
   .screen-xs({
      .ai-font-size(13);
    });
   &.download{
    padding-right:45px;
    color:#fff;
    background-color:#46833D;
    background-image:url("/bca/DataPal15_at/Images/icons/icon-white-download.png");
    background-position:90% 50%;
    background-repeat:no-repeat;
    text-align:left;
    font-weight:600;
    -webkit-transition:background-position 0.7s ease;
    &:hover{
    color:#fff;background-image:url("/bca/DataPal15_at/Images/icons/icon-white-download.png");background-image:url("/bca/DataPal15_at/Images/icons/icon-white-download.png"), linear-gradient(to bottom, #BAD65E 0%, #46833D 100%);background-image:url("/bca/DataPal15_at/Images/icons/icon-white-download.png"),  -moz-linear-gradient(top, #BAD65E 0%, #46833D 100%);background-image:url("/bca/DataPal15_at/Images/icons/icon-white-download.png"),  -ms-linear-gradient(top, #BAD65E 0%, #46833D 100%);background-image:url("/bca/DataPal15_at/Images/icons/icon-white-download.png"),  -o-linear-gradient(top, #BAD65E 0%, #46833D 100%);background-image:url("/bca/DataPal15_at/Images/icons/icon-white-download.png"),  -webkit-gradient(linear, left top, left bottom, color-stop(0, #BAD65E), color-stop(1, #46833D));background-image:url("/bca/DataPal15_at/Images/icons/icon-white-download.png"), -webkit-linear-gradient(top, #BAD65E 0%, #46833D 100%);background-position:90% 60%;-webkit-transition:background-position 0.5s ease;
    }
  }
}
.home-signin-btn{
  padding-right: 30px;
}
