.payment-tab{
    border-bottom:none;
    li{
      min-width: 38%;
      max-width: 38%;

      &:active{
        a{
          border: 1px solid #ccc;
          border-bottom:1px solid #ccc !important;
          color:#707173;
        }
      }
    }
    a{
    background: none;
    border: 1px solid #ccc !important;
    border-bottom: none !important;
    border-radius:4px 4px 0 0 !important;
    &:hover{
      background-color:#fff !important;
      border-color: #ccc;
      color: #707173;
      border-bottom: none;
    }

  }
}
.payment-tab-content {
  border:1px solid #ccc;
  padding:21px 17px;
  &.tab-pane {
    display:none;
  }
  &.active {
    display:block;
  }
}

 .promo-bg{
    background-image:url('/bca/DataPal15_at/images/bg-promo.jpg');
    background-position:top center;
    background-repeat:no-repeat;
  }

  .tmobile-cobrand{
    background: url('/bca/DataPal15_at/images/logo-tmobile.png') no-repeat center left;
    color:#e20074;
    font-family: "Open Sans";
  }
  .idprotect-promo-logo{
    margin-top:20px;}

  .subhdr1{
    font-size: 40px;
    margin-left: 125px;
    font-weight: bold;
    display: block;
    line-height:45px;
    .screen-xs ({
        font-size: 30px !important;
        line-height:30px;
     });
  }

  .subhdr2{
    font-size:30px;
    margin-left:125px;
    display:block;
    font-weight:bold;
    line-height:22px;
    .screen-xs ({
        font-size: 20px !important;
        line-height:26px;
      });
  }

  .how-it-works-list.promo-list {
     li{
         margin-bottom:10px;
      }
  }

 .dobpromo{
  .screen-xs ({
      width:20%;
      display:inline-block;
  });
 }

 .shoppingbasket{
     background-color:#77787B !important;
    p{
      color:#fff !important;
    }
    span{
      width:53px;
      display:block;
    }

 }

