.cookie-consent {
 display:none;
 background:@datapal-primary-color;
 padding:10px 0;
 font-size:13px;
 .cookie-consent-info {
  margin:0 auto;
  color:@datapal-light-color;
  }
 .cookie-consent-info a{
    text-decoration: underline !important;
    color:#fff;
    &:hover{
      text-decoration: underline;
      color:#fff;
    }
  }

}

.cookie-accept {
  background:@datapal-light-color;
  color:#000;
  border:none;
  padding:2px 10px;
  margin-left:10px;
  -webkit-transition:all 0.5s ease;
  -moz-transition:all 0.5s ease;
  -o-transition:all 0.5s ease;
  transition:all 0.5s ease;
  -webkit-border-radius:2px;
  -moz-border-radius:2px;
  border-radius:2px;
  cursor:pointer;
  &:hover {
    background:#d3d3d3;
  }
}
