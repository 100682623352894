.home-info-content__list{
  .home-info-content__list-item{
    list-style-type: none;
    list-style-image:url('/bca/DataPal15_at/Images/icons/icon-tick.png');
    margin-bottom: 25px;
  }
  .home-informaion-list-item:before{
    content:"\e013";
    font-family:"Glyphicons Halflings";
    line-height:1;
    margin:5px;
    color:@datapal-primary-color;
    font-size:smaller
  }
}
.home-information--video{
  width: 87%;
  height: 272px !important;
  .screen-xs({
    width: 100%;
    height: 162px !important;
  });
}
.home-benefits-block{
  margin-bottom: 10px;
  margin-top: 20px;
  padding-top: 20px;
  .home-benefits-block__description{
      padding:10px 0;
  }
  .home-benefits-block__image{
    /*width:100%;*/
  }

}
.signup-block{
  background:@datapal-primary-color;
  margin: 25px 0 !important;
  padding:30px 0;
 .button-animation{
    border: 1px solid #fff;
    margin: 15% 0;
  }
  .signup-block__heading{
    color: @datapal-light-color;
  }
  .signup-block__discription{
    color: @datapal-light-color;
  }
  .signup-block-login{
    padding:40px;
  }
}
.greenfont{
  color:@datapal-primary-color;
}

.login{
  background-image: url('/bca/DataPal15_at/Images/login.jpg');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  margin-bottom: 15px;
  padding-top: 40px;
}

.login-bg{
  background-color: #ffffff;
  border-radius: 5px;
  padding: 20px;
}

.login-discover{
  margin: 80px 0px 50px 0px;
  a{
    text-decoration: none;
  }
}