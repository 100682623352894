.button-animation,.button-animation[disabled]{
   color:#fff;
   padding-right:45px;
   text-align:left;
   background-color:@datapal-primary-color;
   background-image:url("/bca/DataPal15_at/Images/icons/icon-double-chevron.png");
   background-position:90% 50%;
   background-repeat:no-repeat;
   font-weight:600;
   font-size:18px;
   .ai-transition();
   border-radius:4px;
   &:hover, &:focus,&:active{
      color:#fff;
      background-image:url("/bca/DataPal15_at/Images/icons/icon-double-chevron.png");
      background-image:url("/bca/DataPal15_at/Images/icons/icon-double-chevron.png"), linear-gradient(to bottom, #BAD65E 0%, @datapal-primary-color 100%);
      background-image:url("/bca/DataPal15_at/Images/icons/icon-double-chevron.png"), -moz-linear-gradient(top, #BAD65E 0%, @datapal-primary-color 100%);
      background-image:url("/bca/DataPal15_at/Images/icons/icon-double-chevron.png"), -ms-linear-gradient(top, #BAD65E 0%, @datapal-primary-color 100%);
      background-image:url("/bca/DataPal15_at/Images/icons/icon-double-chevron.png"), -o-linear-gradient(top, #BAD65E 0%, @datapal-primary-color 100%);
      background-image:url("/bca/DataPal15_at/Images/icons/icon-double-chevron.png"), -webkit-gradient(linear, left top, left bottom, color-stop(0, #BAD65E), color-stop(1, @datapal-primary-color));
      background-image:url("/bca/DataPal15_at/Images/icons/icon-double-chevron.png"),-webkit-linear-gradient(top, #BAD65E 0%, @datapal-primary-color 100%);background-position:95% 50%;-webkit-transition:background-position 0.7s ease;}

}
.btn-default.disabled{
  background-color: #46833D !important;
}