.site-navbar {
  border-width: 0 !important;
  margin: 5px 0 0 0px!important;
  .screen-xs({
    margin: 0;
    });
}
.site-navigation {
  .site-navigation__item {
    margin-bottom:-1px;
    & a{
      .border-top-radius(4px);
      .screen-sm({
        padding: 15px 10px;
        });
      .screen-xs({
        .border-top-radius(0);
        .border-right-radius(0);
        margin-bottom:2px;
        });
      &.home{
        .ai-font-size(20);
      }
    }
  }
  .site-navigation__webmonitor{
    color: #fff !important;
    background-color: #46833d !important;
    &:active,&:focus,&:hover{
      color:#46833d !important;
      background-color: #fff !important;
    }
  }
}
.ai-dropdown-menu {
  border-top: 0px;
  & a{
    border: none !important;
    background-color: #fff !important;
    color:#46833d !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    &:active,&:focus,&:hover{
      background-color: @datapal-panel-default-dark-border !important;
    }
  }
}