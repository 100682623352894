.skiptocontent{ 
  ul {
      list-style-type:none;
      margin-bottom:0px;
        a {
        padding:6px;
        position: absolute;
        top:-40px;
        left:0px;
        color:@datapal-light-color;
        border-right:1px solid @datapal-primary-color;
        border-bottom:1px solid @datapal-primary-color;
        border-bottom-right-radius:6px;
        background:transparent;
        -webkit-transition: top .1s ease-out, background .1s linear;
          transition: top .1s ease-out, background .1s linear;
          z-index: 100;
        }
  }
}

.skiptocontent a:focus {
  position:absolute;
  left:0px;
  top:0px;
  background:@datapal-primary-color;
  outline:0;  
  -webkit-transition: top .1s ease-in, background .3s linear;
    transition: top .1s ease-in, background .3s linear;
}